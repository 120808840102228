<template>
	<v-app :class="`route--${$route.name}`">
		<v-navigation-drawer
			v-model="drawer"
			:temporary="appBarIsTemporary"
			class="nav-drawer"
		>
			<div class="nav-drawer--info">
				<div class="d-flex align-center px-4 genesis-hue-anim">
					<img
						src="@/assets/sprite/taskbar/genesis_logo.png"
						alt=""
						style="cursor: pointer"
						@click="
							$router.push({
								name: 'home-page',
							})
						"
					/>
					<v-spacer />
					<v-btn
						v-if="$vuetify.display.lgAndUp"
						:icon="
							appBarIsTemporary
								? 'fa-solid fa-thumbtack'
								: 'fa-solid fa-thumbtack fa-rotate-270'
						"
						size="x-small"
						flat
						@click="appBarIsTemporary = !appBarIsTemporary"
					></v-btn>
				</div>

				<v-divider></v-divider>

				<div class="d-flex justify-center align-center">
					<v-chip
						class="ma-2"
						color="upGreen"
						prepend-icon="fa-solid fa-up"
						:to="{
							name: 'units',
							query: {
								statusCategory: 'up',
							},
						}"
					>
						{{ unitStats.up }}
					</v-chip>
					<v-chip
						class="ma-2"
						color="downRed"
						prepend-icon="fa-solid fa-down"
						:to="{
							name: 'units',
							query: {
								statusCategory: 'down',
							},
						}"
					>
						{{ unitStats.down }}
					</v-chip>
				</div>
			</div>

			<div class="nav-drawer--scroll">
				<v-list class="custom-prepend" color="primary">
					<template v-for="item in routes" :key="item.title">
						<template v-if="!item.priv || $userHasPriv(item.priv, false)">
							<v-list-item
								v-if="!item.path"
								:title="item.title"
								:prepend-icon="item.icon"
							>
								<template #append>
									<v-chip size="x-small" color="warning">Coming Soon</v-chip>
								</template>
							</v-list-item>
							<v-list-item
								v-else-if="!item.subRoutes"
								:to="{ path: item.path }"
								:title="item.title"
								:prepend-icon="item.icon"
							>
							</v-list-item>
							<v-list-group
								v-else
								collapse-icon="fa-sharp fa-solid fa-caret-up"
								expand-icon="fa-sharp fa-solid fa-caret-down"
							>
								<template #activator="{ props }">
									<v-list-item
										v-bind="props"
										:title="item.title"
										:prepend-icon="item.icon"
									></v-list-item>
								</template>
								<template v-for="sub in item.subRoutes" :key="sub.title">
									<v-list-item
										v-if="!sub.path"
										:title="sub.title"
										:prepend-icon="sub.icon"
									>
										<template #append>
											<v-chip size="x-small" color="warning">
												Coming Soon
											</v-chip>
										</template>
									</v-list-item>
									<v-list-item
										v-else-if="!sub.priv || $userHasPriv(sub.priv)"
										:to="{ path: sub.path }"
										:title="sub.title"
										:prepend-icon="sub.icon"
									></v-list-item>
								</template>
							</v-list-group>
						</template>
					</template>
				</v-list>
			</div>
		</v-navigation-drawer>

		<!-- Top App Bar -->
		<v-app-bar app class="nav-bar" :priority="-1" density="compact">
			<template #prepend>
				<v-app-bar-nav-icon
					v-if="loggedIn"
					class="nav-drawer-open"
					@click.stop="drawer = !drawer"
				>
					<div class="appBar-logo-scaleWrapper">
						<div class="appBar-logo-animWrapper">
							<img
								id="AppBarLogoStill"
								src="@/assets/genesis_logo_small-still.png"
								alt="GENESIS logo"
							/>
							<div id="AppBarLogoAnim"></div>
						</div>
					</div>
					<!-- <img src="@/assets/logo_small.png" height="36" alt="" /> -->
				</v-app-bar-nav-icon>
			</template>

			<!-- <v-app-bar-title></v-app-bar-title> -->

			<!-- Search -->
			<v-spacer></v-spacer>
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<div class="global-search-trigger">
						<button
							v-if="loggedIn"
							v-bind="props"
							@click="globalSearchShowing = !globalSearchShowing"
						>
							<i class="fa-solid fa-magnifying-glass"></i>
						</button>
					</div>
				</template>
				<span>Activate global search</span>
			</v-tooltip>
			<v-spacer></v-spacer>

			<template #append>
				<template v-if="loggedIn">
					<!-- Help -->
					<v-tooltip location="bottom">
						<template #activator="{ props }">
							<div v-bind="props">
								<v-btn class="help-button" icon size="small">
									<StonlyLogo />
								</v-btn>
							</div>
						</template>
						<span>Tutorials</span>
					</v-tooltip>

					<!-- Bookmark -->
					<v-tooltip location="bottom">
						<template #activator="{ props }">
							<v-btn
								v-bind="props"
								icon
								size="small"
								@click="
									isPageBookmarked
										? removeBookmark(isPageBookmarked.id)
										: addBookmark({
												name: $route.meta.pageTitle,
												url: $route.path,
											})
								"
							>
								<v-icon color="yellow-darken-2">
									{{ isPageBookmarked ? "fa-solid" : "fa-regular" }}
									fa-star
								</v-icon>
							</v-btn>
						</template>
						<span>
							{{ isPageBookmarked ? "Remove Bookmark" : "Add to Bookmarks" }}
						</span>
					</v-tooltip>

					<!-- Bookmarks Menu-->
					<v-menu
						location="start"
						min-width="300"
						close-on-click
						close-on-content-click
					>
						<template #activator="{ props: menu }">
							<v-tooltip location="bottom">
								<template #activator="{ props: tooltip }">
									<v-btn
										v-bind="mergeProps(menu, tooltip)"
										icon="fa-duotone fa-bookmark"
										color="primary"
										size="small"
										@click="showBookmarks"
									></v-btn>
								</template>
								<span>Bookmarks</span>
							</v-tooltip>
						</template>
						<v-card max-width="400">
							<CardHeader title="My Bookmarks" background="primary">
							</CardHeader>
							<v-list class="pa-0">
								<template v-if="bookmarkList.length">
									<v-list-item
										v-for="(item, i) in bookmarkList"
										:key="i"
										value="bookmarkValue"
										title=""
										:class="`bookmark-list-item bookmark-type-${
											item.name.toLowerCase().split(' ')[0]
										}`"
									>
										<template #prepend>
											<v-icon size="x-small">
												fa-duotone fa-circle-bookmark
											</v-icon>
										</template>
										<v-list-item-title
											@click="$router.push({ path: item.url })"
										>
											{{ item.name }}
										</v-list-item-title>
										<template #append>
											<v-btn
												class="bookmark-remove ml-8"
												color="grey-lighten-1"
												icon="fa-solid fa-trash-can"
												variant="text"
												size="x-small"
												title="Remove Bookmark"
												@click="removeBookmark(item.id)"
											></v-btn>
										</template>
									</v-list-item>
								</template>
								<template v-else>
									<v-alert type="info" density="compact" class="ma-2">
										No bookmarks added yet. Click the star on a page to add it
										to your bookmarks!
									</v-alert>
								</template>
							</v-list>
						</v-card>
					</v-menu>

					<!-- Usersnap -->
					<v-tooltip location="bottom">
						<template #activator="{ props }">
							<v-btn
								v-bind="props"
								size="small"
								icon="fa-duotone fa-messages-question"
								color="accentAlt-lighten-2"
								@click="openUserSnap"
							></v-btn>
						</template>
						<span>Submit feedback</span>
					</v-tooltip>

					<!-- Ticket -->
					<v-tooltip location="bottom">
						<template #activator="{ props }">
							<v-btn
								v-bind="props"
								size="small"
								color="typeTicket"
								icon="fak fa-solid-ticket-circle-plus"
								@click="showTicketsForm = !showTicketsForm"
							></v-btn>
						</template>
						<span>Create a ticket</span>
					</v-tooltip>

					<v-divider vertical thickness="2" class="my-2 mx-2" />

					<!-- User -->
					<v-menu location="bottom" close-on-click close-on-content-click>
						<template #activator="{ props }">
							<v-btn
								v-tooltip:bottom="'User Settings'"
								v-bind="props"
								size="small"
								icon="fa-solid fa-user"
							></v-btn>
						</template>

						<v-card min-width="300">
							<CardHeader
								class="nav-user-card-header"
								background="optionA"
								prepend-icon="fa-solid fa-user"
							>
								<template #title>{{ fullname }}</template>
							</CardHeader>

							<v-card-text class="nav-user-links">
								<v-list>
									<v-list-item :to="{ name: 'account' }">
										<v-list-item-title>My Account</v-list-item-title>
									</v-list-item>
									<v-list-item
										v-if="$userHasPriv('canManageUploadedPhotos')"
										:to="{ name: 'photo-management' }"
									>
										<v-list-item-title>Photo Management</v-list-item-title>

										<template
											v-if="total_pictures_pending_approval > 0"
											#append
										>
											<v-badge
												color="warning"
												:content="total_pictures_pending_approval"
												inline
											></v-badge>
										</template>
									</v-list-item>
									<v-list-item :to="{ name: 'faq' }">
										<v-list-item-title>FAQ</v-list-item-title>
									</v-list-item>
									<v-list-item :to="{ name: 'privacy' }">
										<v-list-item-title>Privacy</v-list-item-title>
									</v-list-item>
									<v-list-item :to="{ name: 'terms' }">
										<v-list-item-title>Terms of Service</v-list-item-title>
									</v-list-item>
									<v-list-item :to="{ name: 'learn-more' }">
										<v-list-item-title>Learn More</v-list-item-title>
									</v-list-item>
								</v-list>

								<div class="nav-user-toolset px-4">
									<!-- Preferences -->
									<v-tooltip location="bottom">
										<template #activator="{ props }">
											<v-btn
												v-bind="props"
												class="mx-1"
												size="small"
												color="optionA"
												icon="fa-solid fa-gears"
												@click="preferencesShowing = !preferencesShowing"
											></v-btn>
										</template>
										<span>Set your preferences</span>
									</v-tooltip>

									<!-- Theme -->
									<v-tooltip location="bottom">
										<template #activator="{ props }">
											<v-btn
												v-bind="props"
												class="mx-1"
												size="small"
												color="optionB"
												:icon="
													simplifiedThemeName === 'dark'
														? 'fa-solid fa-sun'
														: 'fa-solid fa-moon'
												"
												@click="toggleTheme"
											>
											</v-btn>
										</template>
										<span>
											Toggle
											{{ simplifiedThemeName === "dark" ? "light" : "dark" }}
											theme
										</span>
									</v-tooltip>
								</div>
							</v-card-text>

							<v-divider></v-divider>

							<v-card-actions>
								<v-btn
									block
									class="px-7"
									variant="text"
									prepend-icon="fa-sharp fa-solid fa-right-from-bracket"
									@click="handleUserLogout"
								>
									Logout
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</template>
			</template>
		</v-app-bar>
		<v-main v-shortkey="shortkeyOptions" @shortkey="kbActions">
			<div :class="`genesis-app-container`">
				<BgAnim />
				<!-- routes -->
				<router-view />

				<!--  dock -->
				<TheDock v-if="loggedIn" />

				<!-- global search --->
				<TheGlobalSearch
					v-if="loggedIn && globalSearchShowing"
					v-model="globalSearchShowing"
					@navigated-from-search-result="globalSearchShowing = false"
				/>

				<!-- preferences --->
				<ThePreferences
					v-if="loggedIn && preferencesShowing"
					v-model="preferencesShowing"
				/>

				<!-- create ticket -->
				<TicketsForm v-if="showTicketsForm" v-model="showTicketsForm" />

				<v-snackbar
					v-if="loggedIn"
					v-model="snackbar"
					multi-line
					:timeout="-1"
					color="deep-purple-lighten-1"
				>
					GENESIS has been updated. Please save any work done and press the
					reload button.
					<template #actions>
						<v-btn
							variant="text"
							append-icon="fa-solid fa-redo-alt"
							@click="reloadPage"
						>
							Reload
						</v-btn>
					</template>
				</v-snackbar>
			</div>
		</v-main>
	</v-app>
</template>

<script>
import { mergeProps } from "vue";
import { useTheme } from "vuetify";

import { useUserStore } from "@/stores/store.user";
import { useDockStore } from "@/stores/store.dock";
import { useBookmarkStore } from "@/stores/store.bookmark";

import { NAV_LIST } from "./helpers/AppNavList";

export default {
	setup() {
		const theme = useTheme();
		return { theme };
	},
	data() {
		return {
			appBarIsTemporary: true,
			globalSearchShowing: false,
			preferencesShowing: false,
			drawer: false,
			routes: NAV_LIST,
			snackbar: false,
			showTicketsForm: false,
			bookmarksMenuOpen: false,
			usersnapIsOpen: false,
		};
	},
	computed: {
		...mapState(useBookmarkStore, ["bookmarkList"]),
		...mapState(useUserStore, [
			"loggedIn",
			"usersnapMeta",
			"fullname",
			"total_pictures_pending_approval",
		]),
		...mapState(useDockStore, {
			unitStats: (store) => store.unit_stats,
			mismatchedVersion: (store) => {
				//length check is to check if dock has fetched
				//then check db version with front end
				return (
					store.genesis_version.length &&
					store.genesis_version !== `v${__APP_VERSION__}`
				);
			},
		}),
		...mapWritableState(useUserStore, ["vuetifyTheme"]),
		isPageBookmarked() {
			const found = this.bookmarkList.find(
				(item) => item.url === this.$route.path,
			);
			return found;
		},
		simplifiedThemeName() {
			if (this.theme.global.name.value === "GenesisDarkTheme") {
				return "dark";
			} else {
				return "light";
			}
		},
		shortkeyOptions() {
			if (this.usersnapIsOpen) {
				return {};
			} else {
				return {
					kbGlobalSearch: ["shift", "s"],
					kbNavigationDrawer: ["shift", "b"],
				};
			}
		},
	},
	watch: {
		mismatchedVersion: {
			handler(newVal) {
				if (newVal) {
					this.snackbar = true;
				} else {
					this.snackbar = false;
				}
			},
			// immediate callback execution
			immediate: true,
		},
		// re-enable for bookmark implementation
		loggedIn(newVal) {
			if (newVal) {
				this.getBookmarks();
			}
		},
	},
	updated() {
		const appBarLogoAnim = document.getElementById("AppBarLogoAnim");

		let currentFrame = 0;
		let currentFrameOffsetX = 0;
		let frameWidth = 44.9;
		let frameCount = 64;
		let frameDuration = 30;
		// let loop = {};

		function advanceToNextFrame() {
			currentFrame = currentFrame === frameCount ? 0 : currentFrame + 1;
			currentFrameOffsetX = currentFrame * frameWidth;

			if (appBarLogoAnim) {
				appBarLogoAnim.style.backgroundPosition = `-${currentFrameOffsetX}px 0px`;
			}
		}

		setInterval(advanceToNextFrame, frameDuration);
	},
	methods: {
		mergeProps,
		...mapActions(useBookmarkStore, [
			"getBookmarks",
			"addBookmark",
			"removeBookmark",
		]),
		...mapActions(useUserStore, ["handleUserLogout"]),
		kbActions(event) {
			switch (event.srcKey) {
				case "kbGlobalSearch":
					if (this.loggedIn) {
						this.globalSearchShowing = !this.globalSearchShowing;
					}
					break;
				case "kbNavigationDrawer":
					if (this.loggedIn) {
						this.drawer = !this.drawer;
					}
					break;
			}
		},
		reloadPage() {
			window.location.reload();
		},
		showBookmarks() {
			this.bookmarksMenuOpen = !this.bookmarksMenuOpen;
			this.getBookmarks();
		},
		openUserSnap() {
			const vm = this;
			const usersnapApi = window.Usersnap;

			usersnapApi.on("open", function (event) {
				event.api.setValue("visitor", vm.usersnapMeta.email);
				event.api.setValue("custom", vm.usersnapMeta);
				vm.usersnapIsOpen = true;
			});

			usersnapApi.on("close", function () {
				vm.usersnapIsOpen = false;
			});

			usersnapApi.open();
		},
		toggleTheme() {
			this.theme.global.name.value =
				this.theme.global.name.value === "GenesisDarkTheme"
					? "GenesisLightTheme"
					: "GenesisDarkTheme";

			this.vuetifyTheme = this.theme.global.name.value;
		},
	},
};
</script>

<style lang="scss">
.help-button {
	visibility: hidden;
	pointer-events: none;
}
.v-application:is(
		[class*="route--map"],
		[class*="route--diagnostic"],
		[class*="route--ota"],
		[class*="route--reporting"],
		[class*="route--tickets"],
		[class*="route--unit"],
		[class*="route--units"],
		[class*="route--units-all"],
		[class*="route--usage"],
		[class*="route--home-page"] .homepage-card-body
	)
	.help-button {
	visibility: visible;
	pointer-events: all;
}

.route--map .genesis-app-container .genesis-bg-anim {
	display: none;
}

.v-tooltip {
	.v-overlay__content {
		background-color: rgba(var(--v-theme-background), 0.9);
		color: rgb(var(--v-theme-primaryText));
	}
}

.genesis-hue-anim {
	animation: genesisHueAnim 5s infinite alternate both;
}

@keyframes genesisHueAnim {
	0% {
		filter: hue-rotate(-190deg);
	}
	100% {
		// filter: hue-rotate(50deg);
		filter: hue-rotate(0deg);
	}
}

.nav-user-card-header {
	.v-avatar {
		--v-avatar-height: 50px;
		background-color: rgb(var(--v-theme-on-surface-variant));
	}
}

.nav-user-links {
	position: relative;
}
.nav-user-toolset {
	position: absolute;
	top: -10px;
	right: 0;
}

.global-search-trigger {
	flex-basis: 220px;
	position: relative;
	flex-shrink: 1;
	transition: flex-basis 200ms ease-out;

	button {
		width: 100%;
		height: 28px;
		background-color: rgb(var(--v-theme-on-surface-variant));
		border-radius: 2786px;
		text-align: left;
		cursor: pointer;

		i {
			width: 30px;
			margin: 0 calc(50% - 15px);
			display: block !important;
			text-align: center;
			transition: margin 200ms ease-out;
		}
	}
	&:hover {
		flex-basis: 500px;

		i {
			margin: 0 8px 0 2px;
		}
	}
}

.nav-drawer {
	font-size: 0.8em;

	.v-list-group__items {
		--indent-padding: 1.4rem;
	}
	.v-list-item-title {
		font-size: 0.8rem;
	}
}

.nav-drawer--info {
	// display: flex;
	// flex-direction: column;
	// justify-content: flex-start;

	.genesis-hue-anim {
		height: var(--toolbar-height);
	}
}

// Bookmarks
.bookmark-remove {
	opacity: 0;
	pointer-events: none;
	transition: opacity 300ms ease-in-out;
}
.bookmark-list-item {
	&:hover {
		.bookmark-remove {
			opacity: 1;
			pointer-events: all;
		}
	}
}
[class*="bookmark-type-info"] {
	color: #aa55ff;
}
.bookmark-type {
	&-map {
		color: #ff3636;
	}
	&-diagnostic {
		color: #ff6929;
	}
	&-engineering {
		color: #ffa02c;
	}
	&-installation {
		color: #ffdd00;
	}
	&-monitoring {
		color: #a6ff29;
	}
	&-network {
		color: #37ff25;
	}
	&-notification {
		color: #27ffa8;
	}
	&-ordering {
		color: #1cf9fd;
	}
	&-otas {
		color: #21aafe;
	}
	&-reporting {
		color: #386dff;
	}
	&-site {
		color: #404dff;
	}
	&-smartlist {
		color: #6735ff;
	}
	&-ticket {
		color: #912bff;
	}
	&-tsc {
		color: #d325ff;
	}
	&-units {
		color: #ff2ce6;
	}
	&-usage {
		color: #ff2285;
	}
	&-user {
		color: #ff4787;
	}
}

/* temporary fix, see github on fill-height */
/* https://github.com/vuetifyjs/vuetify/issues/9773 */
.container.fill-height > .row {
	max-width: initial !important;
}
.v-tab {
	font-size: 0.8rem !important;
}
.route--login {
	.genesis-app-container {
		height: 100%;
		display: flex;
	}
}
.v-layout {
	overflow: auto !important;
}
//added 4/19/2022, so no more darker/grey labels
.v-label {
	opacity: 1 !important;
}

nav.v-navigation-drawer .v-navigation-drawer__content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.nav-drawer--scroll {
	flex: 1 1 auto;
	height: 100%;
	max-width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

nav.v-navigation-drawer .v-navigation-drawer__content .v-list {
	padding: 0 0 1px 0;

	.v-list-item {
		background-image: linear-gradient(
			rgba(var(--v-theme-on-surface), 0.03),
			rgba(var(--v-theme-on-surface), 0.03)
		);
		background-repeat: no-repeat;
		background-position: top;
		background-size: 100% calc(100% - 1px);

		&:hover {
			box-shadow: 0 1px 0 rgb(var(--v-theme-primary));
		}

		&.v-list-item--active + .v-list-group__items .v-list-item {
			color: light-dark(
				rgb(var(--v-theme-primary-darken-2)),
				rgb(var(--v-theme-primary-lighten-1))
			);
		}
	}
}

/* temporary fix, see github on fill-height */
/* https://github.com/vuetifyjs/vuetify/issues/9130 */
.v-card__text,
.v-card__title {
	word-break: normal; /* maybe !important  */
}

$genesisLogo-width: 44.9px;
$genesisLogo-height: 48px;
$genesisLogo-scale: 1;

.appBar-logo-scaleWrapper {
	width: ($genesisLogo-width * $genesisLogo-scale);
	min-width: ($genesisLogo-width * $genesisLogo-scale);
	height: ($genesisLogo-height * $genesisLogo-scale);
	position: relative;
	margin: 5px;

	.appBar-logo-animWrapper {
		position: absolute;
		inset: 0 0 0 0;
		overflow: hidden;
	}
}

#AppBarLogoStill {
	width: 44.9px;
	height: 47px;
	background-image: url("@/assets/genesis_logo_small-still.png");
}

#AppBarLogoAnim {
	width: 44.9px;
	height: 47px;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url("@/assets/genesis_logo-anim.png");
	background-size: auto 100%;
}
</style>
