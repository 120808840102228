<template>
	<v-card-item :class="basic ? '' : 'card-title-bg'">
		<template #prepend>
			<slot name="icon"></slot>
		</template>
		<v-card-title :class="color">
			<template v-if="title">{{ title }}</template>
			<slot v-else name="title"></slot>
		</v-card-title>
		<v-card-subtitle :class="color2">
			<template v-if="subtitle">{{ subtitle }}</template>
			<slot v-else name="subtitle"></slot>
		</v-card-subtitle>
		<template #append>
			<slot name="actions"></slot>
		</template>
	</v-card-item>
</template>

<script>
export default {
	props: {
		basic: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "",
		},
		subtitle: {
			type: String,
			default: "",
		},
		background: {
			type: String,
			default: "primary",
		},
		color: {
			type: String,
			default: "",
		},
		color2: {
			type: String,
			default: "",
		},
	},
	data() {
		return {};
	},
	methods: {
		convertColor(alpha) {
			return `rgba(var(--v-theme-${this.background}), ${alpha})`;
		},
	},
};
</script>

<style lang="scss">
.v-card-item .v-card-subtitle {
	padding: 0;
}
.v-dialog > .v-overlay__content > .v-card > .v-card-item {
	padding: 0.625rem 1rem;
}
.v-card-item + .v-card-text {
	padding-top: 0.5rem;
}
</style>
<style scoped lang="scss">
.card-title-bg {
	// background-color: v-bind(convertColor("0.05"));
	// box-shadow: 0 -1px 0 0 v-bind(convertColor("0.5")) inset;
	background-image: linear-gradient(
		180deg,
		light-dark(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.1)),
		light-dark(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.1))
	);
}

.v-card-title {
	white-space: normal !important;
}
</style>
